import React from 'react';

class VolumeSlider extends React.Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}

export default VolumeSlider